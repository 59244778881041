import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import { Vortex } from "react-loader-spinner";
import { auto } from "@popperjs/core";
import lottie from "lottie-web";
import reactLogo from "../static/MinkWhite loader.json";
import index from "../v2/Pages/Login/index";
import ShowHotel from "../v2/Pages/ShowHotel/ShowHotel";
import Signup from "../v2/Pages/Signup/Signup";
import Search from "../v2/Pages/Search/Search";
import Listing from "../v2/Pages/RegisterBusiness/Listing";
import Contact from "../v2/Pages/Contact/Contact";
import Book from "../v2/Pages/BookHotel/Book";
import DashboardHome from "./User/Dashboard/DashboardHome";
import UsersProfileHome from "./User/Dashboard/Consumer/Profile/UsersProfileHome";
import EditHotelHome from "./User/Dashboard/Manager/Hotel/EditHotelHome";
import HotelBookingsHome from "./User/Dashboard/Manager/HotelBookingsHome";
import CategoriesHome from "./User/Dashboard/Admin/Categories/CategoriesHome";
import UserOrderHome from "./User/Dashboard/Consumer/Orders/UserOrderHome";
import ManagersHome from "./User/Dashboard/Admin/RequestForBusinesses/Users/ManagersHome";
import AllHotelsHome from "./User/Dashboard/Admin/Hotels/AllHotelsHome";
import AboutMinkwhiteHome from "./Home/Footer/About/AboutMinkwhiteHome";
import TermsofServiceHome from "./Home/Footer/About/TermsofServiceHome";
import WhatWeOfferHome from "./Home/Footer/MinkForBusiness/WhatWeOfferHome";
import OurStoryOurMissionHome from "./Home/Footer/Discover/OurStoryOurMissionHome";
import MessagesHome from "./Businesses/Business/AddBusiness/Messages/MessagesHome";
import AllBusinessesHome from "./User/Dashboard/Admin/AllBusinessesHome";

const MyNetwork = lazy(
  () =>
    new Promise((resolve) =>
      setTimeout(
        () => resolve(import("./User/Dashboard/Network/MyNetwork")),
        1000
      )
    )
);

const MyReviews = lazy(() =>
  import("./User/Dashboard/Consumer/MyReviews/Reviews")
);
const AllTab = lazy(() =>
  import("./User/Dashboard/Admin/CustomerQuery/AllTab")
);

const AddBusiness = lazy(() =>
  import("../components/Businesses/Business/AddBusiness/AddBusiness")
);
const BusinessShow = lazy(() =>
  import(
    "../components/Businesses/Business/AddBusiness/AboutBusiness/BusinessShow/BusinessShow"
  )
);
const BusinessSearch = lazy(() =>
  import(
    "../components/Businesses/Business/AddBusiness/AboutBusiness/BusinessSearch/BusinessSearch"
  )
);
const BusinessPhoto = lazy(() =>
  import(
    "../components/Businesses/Business/AddBusiness/AboutBusiness/BusinessPhotos/BusinessPhoto"
  )
);
const Review = lazy(() =>
  import("../components/Businesses/Business/AddBusiness/Reviews/ReviewModal")
);

const AllBusinesses = lazy(() =>
  import("./User/Dashboard/Admin/AllBusinesses")
);
const AddBusinessManager = lazy(() =>
  import("./User/Dashboard/Manager/AddBusiness")
);
const businesses = lazy(() =>
  import("./User/Dashboard/Admin/Photos/BusinessesPhotos")
);
const Reviews = lazy(() =>
  import("./User/Dashboard/Admin/Review/BusinessesReviews")
);
const Messaging = lazy(() =>
  import("./Businesses/Business/AddBusiness/Messages/Messages")
);

const UserInterest = lazy(() => import("./User/Login/UserInterest"));
// const AllHotels = lazy(() => import("./User/Dashboard/Admin/Hotels/AllHotels"));

const phoneBook = lazy(() =>
  import("./User/Dashboard/Consumer/Profile/phoneBook")
);

const PageError = lazy(() => import("./UI/PageError"));
const Message = lazy(() => import("./User/Dashboard/Consumer/Profile/Message"));
const PrivacyPolicy = lazy(() => import("./User/Login/PrivacyPolicy"));

const ClaimYourBusinessPage = lazy(() =>
  import("./Home/Footer/MinkForBusiness/ ClaimYourBusinessPage")
);
const PartnerwithMinkWhite = lazy(() =>
  import("./Home/Footer/MinkForBusiness/PartnerwithMinkWhite")
);
const WhatWeOffer = lazy(() =>
  import("./Home/Footer/MinkForBusiness/WhatWeOffer")
);
const ResetPassword = lazy(() =>
  import("./User/Login/ForgetPassword/ResetPassword")
);
const AllRooms = lazy(() => import("./User/Dashboard/Admin/Rooms/AllRooms"));
const About = lazy(() => import("./Home/Footer/About/About"));
const Allusers = lazy(() =>
  import("./User/Dashboard/Admin/RequestForBusinesses/Users/Allusers")
);

const HomeDemo = lazy(() => import("../v2/Pages/Home/index"));

const App = () => {
  useEffect(() => {
    ReactGA.initialize("G-MPDDE55LC4");
    ReactGA.send("pageview");
  }, []);
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#react-loder"),
      animationData: reactLogo,
      renderer: "svg", // "canvas", "html"
      loop: true, // boolean
      autoplay: true, // boolean
    });
  }, []);
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div
            id="react-loder"
            className=" h-36 w-36 justify-center m-auto flex mt-64"
          />
        }
      >
        <Switch>
          <Route exact path="/dashboard" component={DashboardHome} />
          <Route exact path="/categories" component={CategoriesHome} />
          <Route exact path="/businesses/search" component={BusinessSearch} />
          <Route exact path="/business" component={AddBusiness} />
          <Route exact path="/businesses/:id" component={BusinessShow} />
          <Route exact path="/business/:id/photos" component={BusinessPhoto} />
          <Route exact path="/business/id/review" component={Review} />
          <Route exact path="/hotels" component={AllHotelsHome} />
          <Route exact path="/businesses" component={AllBusinessesHome} />
          <Route exact path="/add_business" component={AddBusinessManager} />
          <Route exact path="/business_photos" component={businesses} />
          <Route exact path="/businesses_reviews" component={Reviews} />
          <Route exact path="/messaging" component={MessagesHome} />
          <Route exact path="/my_reviews" component={MyReviews} />
          <Route exact path="/dashboard/mynetwork" component={MyNetwork} />
          <Route exact path="/user_interests" component={UserInterest} />

          <Route exact path="/see" component={About}></Route>
          <Route exact path="/orders_details" component={UserOrderHome}></Route>
          <Route
            exact
            path="/user_profile"
            component={UsersProfileHome}
          ></Route>
          <Route exact path="/phoneBook" component={phoneBook}></Route>
          <Route exact path="/Message" component={Message}></Route>
          <Route exact path="/about_us" component={AboutMinkwhiteHome} />
          <Route
            exact
            path="/terms_of_service"
            component={TermsofServiceHome}
          />
          <Route exact path="/privacy_policy" component={PrivacyPolicy} />
          <Route exact path="/our_story" component={OurStoryOurMissionHome} />

          <Route exact path="/support" component={AllTab} />
          <Route
            exact
            path="/claim_business"
            component={ClaimYourBusinessPage}
          />
          <Route exact path="/reset_password" component={ResetPassword}></Route>
          <Route
            exact
            path="/partner_with_minkwhite"
            component={PartnerwithMinkWhite}
          />
          <Route exact path="/we_offer" component={WhatWeOfferHome} />
          <Route exact path="/users_details" component={Allusers} />
          <Route exact path="/managers_details" component={ManagersHome} />
          <Route exact path="/rooms_details" component={AllRooms} />

          <Route exact path="/listing" component={Listing} />

          <Route exact path="/hotels/:id" component={ShowHotel} />
          <Route exact path="/hotels/:id/booking" component={Book} />
          <Route exact path="/hotel_search" component={Search} />
          <Route exact path="/property/:id/edit" component={EditHotelHome} />
          <Route
            exact
            path="/property/:id/orders"
            component={HotelBookingsHome}
          />
          <Route exact path="/" component={HomeDemo} />
          <Route exact path="/login" component={index} />

          <Route exact path="/signup" component={Signup} />

          <Route exact path="/contact_us" component={Contact} />

          <Route path={"*"} component={PageError}></Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
export default App;
