import React, { useEffect, lazy, Suspense } from "react";
import reactLogo from "../../../../../static/MinkWhite loader.json";
import lottie from "lottie-web";
const IndexLazy = lazy(() => import("./UsersProfile"));

const UsersProfileHome = () => {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#react-loder-login"),
      animationData: reactLogo,
      renderer: "svg", // "canvas", "html"
      loop: true, // boolean
      autoplay: true, // boolean
    });
  }, []);
  return (
    <Suspense
      fallback={
        <div
          id="react-loder-login"
          className=" h-36 w-36 justify-center m-auto flex mt-64"
        />
      }
    >
      {" "}
      <IndexLazy />
    </Suspense>
  );
};

export default UsersProfileHome;
