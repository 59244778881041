import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import { Provider } from "react-redux";
import ActionCable from "actioncable";
import ActionCableProvider from "react-actioncable-provider";
import store from "./store/Index";
import "regenerator-runtime/runtime";
const cable = ActionCable.createConsumer(process.env.REACT_APP_CONSUMER);
ReactDOM.render(
  <ActionCableProvider cable={cable}>
    <Provider store={store}>
      <App />
    </Provider>
  </ActionCableProvider>,
  document.getElementById("root")
);
